import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import pluscircle from "../homework/assets/images/plus circle_.png";
import grp713 from "../homework/assets/images/Group 713.png";
import axiosInstance from "../../axiosInterceptor";
import { dateFormat } from "../../dateFormat";
import delete1 from "../homework/assets/images/delete.png";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const MultipleNumbers = () => {
  const [loading, setLoading] = useState(false);
  const [helpData, setHelpData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const perPage = 10;
  const navigate = useNavigate();

  const fetchData = async (page = 1) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        `admin/contactNumber/getAllContactNumbers`,
        {
          perPage: perPage,
          pageNo: page,
        }
      );
      const { data, totalPages } = response.data;
      setHelpData(data);
      setTotalPages(totalPages);
      setCurrentPage(page);
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      fetchData(page);
    }
  };

  const handleDelete = async (id) => {
    setDeleteItemId(id);
    setModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axiosInstance.delete(
        `admin/contactNumber/deleteContactNumber/${deleteItemId}`
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setDeleteItemId(null);
      setModalOpen(false);
    }
  };

  return (
    <div>
      <div className="my-2">
        <div className="row mb-2">
          <div className="d-flex justify-content-between">
            <div>
              <h4 className="page-title">Website Numbers</h4>
            </div>
            <div>
              <Link to="/add-website-numbers">
                <Button className="action-button add-from-new">
                  <img
                    src={pluscircle}
                    alt="plus"
                    width={20}
                    className="ADD_code"
                  />
                  <span className=""> Add </span>
                </Button>
              </Link>
            </div>
          </div>
        </div>

        <Card>
          <CardBody>
            <div className="table-responsive">
              <Table
                bordered
                responsive
                className="mt-4 text-center"
                style={{ fontSize: "0.8rem" }}
              >
                <thead>
                  <tr>
                    <th>Position</th>
                    <th>Contact Numbers</th>
                    <th>Country Name</th>
                    <th>Updated At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="5" className="text-center">
                        <span>Loading...</span>
                      </td>
                    </tr>
                  ) : helpData.length > 0 ? (
                    helpData.map((data, index) => (
                      <tr key={index}>
                        <td>{data.position}</td>
                        <td>
                          <PhoneInput
                            value={data.contactNumber}
                            enableSearch
                            disabled
                            disableDropdown
                            inputStyle={{
                              width: "100%",
                              backgroundColor: "transparent",
                              border: "none",
                              fontSize: "0.9rem",
                              color: "#000",
                            }}
                          />
                        </td>
                        <td>{data.countryName}</td>
                        <td>{dateFormat(data.updatedAt)}</td>
                        <td className="d-flex justify-content-center">
                          <div className={`${Styles.edit_code}`}>
                            <Button
                              className={`${Styles.edit_code}`}
                              onClick={() => {
                                navigate("/add-website-numbers", {
                                  state: data,
                                });
                              }}
                            >
                              <img src={grp713} width={19} alt="grp" />
                              Edit
                            </Button>
                          </div>
                          <div
                            className={`${Styles.delete_button_code} me-2 mx-2`}
                          >
                            <Button
                              className={`${Styles.delete_button_code}`}
                              onClick={() => handleDelete(data._id)}
                            >
                              <img
                                src={delete1}
                                className={`${Styles.delete_icon}`}
                                alt="delete"
                              />
                              Delete
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No data found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <div className="float-end">
              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    previous
                    onClick={() => handlePageClick(currentPage - 1)}
                  />
                </PaginationItem>
                {[...Array(totalPages)].map((_, i) => (
                  <PaginationItem key={i} active={i + 1 === currentPage}>
                    <PaginationLink onClick={() => handlePageClick(i + 1)}>
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink
                    next
                    onClick={() => handlePageClick(currentPage + 1)}
                  />
                </PaginationItem>
              </Pagination>
            </div>
          </CardBody>
        </Card>
      </div>
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        style={{ top: "33%" }}
      >
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody>Are you sure you want to delete this numbers?</ModalBody>
        <ModalFooter>
          <div className={`${Styles.button_color}`}>
            <Button type="cancel" onClick={() => setModalOpen(!modalOpen)}>
              No
            </Button>
          </div>
          <div className={`${Styles.cancel_button} me-4`}>
            <Button
              type="cancel"
              className={`${Styles.cancel_button}`}
              onClick={confirmDelete}
            >
              Yes
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default MultipleNumbers;
