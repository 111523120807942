import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const AddMultipleNumbers = () => {
  const location = useLocation();
  const editData = location.state;
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    position: "",
    contactNumber: "",
    countryName: "",
    countryFlag: "",
  });

  const [showInHeader, setShowInHeader] = useState(false);
  const [showInFooter, setShowInFooter] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (editData?._id) {
      setFormData({
        position: editData.position || "",
        contactNumber: editData.contactNumber || "",
        countryName: editData.countryName || "",
        countryFlag: editData.countryFlag || "",
      });
      setShowInHeader(editData.showInHeader || false);
      setShowInFooter(editData.showInFooter || false);
    }
  }, [editData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePhoneChange = (value, countryData) => {
    setFormData((prevData) => ({
      ...prevData,
      contactNumber: value,
      countryName: countryData.name,
      countryFlag: countryData.countryCode,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (name === "showInHeader") {
      setShowInHeader(checked);
    } else if (name === "showInFooter") {
      setShowInFooter(checked);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (!formData.position || !formData.contactNumber) {
      return;
    }

    const dataToSubmit = {
      ...formData,
      showInHeader,
      showInFooter,
    };

    try {
      const response = await axiosInstance.post(
        "admin/contactNumber/createContactNumber",
        dataToSubmit
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        navigate("/website-numbers");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (!formData.position || !formData.contactNumber) {
      return;
    }

    const dataToUpdate = {
      ...formData,
      showInHeader,
      showInFooter,
    };

    try {
      const response = await axiosInstance.put(
        `admin/contactNumber/updateContactNumber/${editData?._id}`,
        dataToUpdate
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        navigate("/website-numbers");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  return (
    <div>
      <div className="mt-4 mb-4">
        <div className="row">
          <div className="col-sm-6">
            <h4 className="page-title">Add Website Numbers</h4>
          </div>
        </div>
        <Card className="mt-4">
          <CardBody>
            <Row>
              <form
                className="ps-5 pe-5 pt-3"
                onSubmit={editData?._id ? handleUpdate : handleSubmit}
              >
                <Row>
                  <Col md="8">
                    <FormGroup row className="mb-4">
                      <Label for="position" className="col-sm-4 col-form-label">
                        Position
                      </Label>
                      <Col sm="8">
                        <Input
                          type="number"
                          name="position"
                          id="position"
                          value={formData.position}
                          onChange={handleInputChange}
                          placeholder="Enter a position"
                          invalid={formSubmitted && !formData.position}
                        />
                        <FormFeedback>Position is required</FormFeedback>
                      </Col>
                    </FormGroup>
                    <FormGroup row className="mb-4">
                      <Label
                        for="contactNumbers"
                        className="col-sm-4 col-form-label"
                      >
                        Contact Numbers
                      </Label>
                      <Col sm="8">
                        <PhoneInput
                          country={"us"}
                          value={formData.contactNumber}
                          onChange={handlePhoneChange}
                          inputProps={{
                            name: "contactNumber",
                            required: true,
                            autoFocus: true,
                          }}
                          inputStyle={{ width: "100%" }}
                          isInvalid={formSubmitted && !formData.contactNumber}
                        />
                        {formSubmitted && !formData.contactNumber && (
                          <FormFeedback className="d-block">
                            Contact number are required
                          </FormFeedback>
                        )}
                      </Col>
                    </FormGroup>
                    {/* Checkbox options */}
                    <FormGroup row className="mb-4">
                      <Col sm="4"></Col>
                      <Col sm="8">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              name="showInHeader"
                              checked={showInHeader}
                              onChange={handleCheckboxChange}
                            />
                            Show in Header
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              name="showInFooter"
                              checked={showInFooter}
                              onChange={handleCheckboxChange}
                            />
                            Show in Footer
                          </Label>
                        </FormGroup>
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-4 col-form-label"
                  >
                    <b className="ms-2"></b>
                  </Label>
                  <div className="col-sm-7 d-flex">
                    <div className={`${Styles.submit_button}`}>
                      <Button type="submit">
                        {editData?._id ? "Update" : "Save"}
                      </Button>
                    </div>
                    <div className={`${Styles.cancel_button} me-4 mx-2`}>
                      <Link to="/website-numbers">
                        {" "}
                        <Button className={`${Styles.cancel_button}`}>
                          Cancel
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AddMultipleNumbers;
